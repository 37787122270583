import { render, staticRenderFns } from "./PerWork.vue?vue&type=template&id=a1f6d00c&scoped=true"
import script from "./PerWork.vue?vue&type=script&lang=js"
export * from "./PerWork.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1f6d00c",
  null
  
)

export default component.exports